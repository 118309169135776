jQuery(document).ready(function() {
    jQuery('#ourCoursesFilterButton').on('click', function() {
        var cat = jQuery('#ourCoursesFilterSelect').find(':selected').attr('data-cat');

        if (cat == '') {
            jQuery('.course-card').show();
        } else {
            jQuery('.course-card').each(function() {
                if (jQuery(this).attr('data-cat') !== cat) {
                    jQuery(this).hide();
                } else {
                    jQuery(this).show();
                }
            });
        }
    });
});

if (jQuery(".our-courses-gallery").length) {
    if (performance.navigation.type == 2){
        location.reload(true);
    }
}

if (jQuery(".our-courses-gallery").length || jQuery(".card-filter-gallery").length) {
    document.addEventListener('DOMContentLoaded', function () {
        const searchInput = document.querySelector('input[name="course_name"]');
        const courseCards = document.querySelectorAll('.course-card');

        function debounce(func, delay) {
            let timeout;
            return function () {
                const context = this, args = arguments;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), delay);
            };
        }

        function filterCourses() {
            const searchQuery = searchInput.value.toLowerCase();

            courseCards.forEach(function (card) {
                const courseName = card.getAttribute('data-course-name').toLowerCase();

                if (courseName.includes(searchQuery)) {
                    jQuery(card).parent().fadeIn();
                } else {
                    jQuery(card).parent().fadeOut();
                }
            });
        }

        const debouncedFilterCourses = debounce(filterCourses, 300);

        searchInput.addEventListener('input', debouncedFilterCourses);
    });
}