jQuery('#ourClubsSportsRepeater').slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    rows: 0,
    focusOnSelect: true,
    centerMode: false,
    arrows: true,
    prevArrow: jQuery(".sports-slider-prev"),
    nextArrow: jQuery(".sports-slider-next"),
    responsive: [
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#ourClubsCreativeRepeater').slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    rows: 0,
    focusOnSelect: true,
    arrows: true,
    prevArrow: jQuery(".creative-slider-prev"),
    nextArrow: jQuery(".creative-slider-next"),
    responsive: [
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#ourClubsDanceFitnessRepeater').slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    rows: 0,
    focusOnSelect: true,
    arrows: true,
    prevArrow: jQuery(".dancefitness-slider-prev"),
    nextArrow: jQuery(".dancefitness-slider-next"),
    responsive: [
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#ourClubsPerformingArtsRepeater').slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    rows: 0,
    focusOnSelect: true,
    arrows: true,
    prevArrow: jQuery(".performingarts-slider-prev"),
    nextArrow: jQuery(".performingarts-slider-next"),
    responsive: [
        {
            breakpoint: 1250,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#sportsGallery').slick({
    slidesToShow: 2.35,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    focusOnSelect: true,
    arrows: true,
    centerMode: true,
    centerPadding: '50px',
    prevArrow: jQuery(".sports-gallery-slider-prev"),
    nextArrow: jQuery(".sports-gallery-slider-next"),
    responsive: [
        {
            breakpoint: 1200,
            setting: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
});

jQuery('#creativeGallery').slick({
    slidesToShow: 2.35,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '50px',
    arrows: true,
    prevArrow: jQuery(".creative-gallery-slider-prev"),
    nextArrow: jQuery(".creative-gallery-slider-next"),
    responsive: [
        {
            breakpoint: 1200,
            setting: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#danceGallery').slick({
    slidesToShow: 2.35,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '50px',
    arrows: true,
    prevArrow: jQuery(".dance-gallery-slider-prev"),
    nextArrow: jQuery(".dance-gallery-slider-next"),
    responsive: [
        {
            breakpoint: 1200,
            setting: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('#artsGallery').slick({
    slidesToShow: 2.35,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '50px',
    arrows: true,
    prevArrow: jQuery(".arts-gallery-slider-prev"),
    nextArrow: jQuery(".arts-gallery-slider-next"),
    responsive: [
        {
            breakpoint: 1200,
            setting: {
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0'
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
}); 

jQuery('.list.mobile select').on('change', function() {
    var val = jQuery(this).val();
    if (val == 'sports') {
        jQuery('.list.desktop #sports').click();
    } else if (val == 'creative') {
        jQuery('.list.desktop #creative').click();
    } else if (val == 'fitness') {
        jQuery('.list.desktop #fitness').click();
    } else if (val == 'arts') {
        jQuery('.list.desktop #arts').click();
    }
});

jQuery('.our-clubs-sliders .list li').on('click', function() {
    var type = jQuery(this).attr('id');

    jQuery('.our-clubs-sliders .clubs-slider').addClass('hidden');
    jQuery('.our-clubs-sliders .arrows').addClass('hidden');
    jQuery('.our-clubs-sliders .arrows').css('padding-top', '0');
    jQuery('.our-clubs-sliders .list li').removeClass('active');
    jQuery('.our-clubs-sliders .gallery').addClass('hidden');

    if (type == 'sports') {
        jQuery('#ourClubsSportsRepeater').removeClass('hidden');
        jQuery('#ourClubsSportsArrows').removeClass('hidden');
        jQuery('#ourClubsSportsArrows').css('padding-top', '30px');
        jQuery('.our-clubs-sliders .list #sports').addClass('active');
        jQuery('.our-clubs-sliders #sportsGallery').removeClass('hidden');
        jQuery('#sportsGalleryArrows').removeClass('hidden');
    } else if (type == 'creative') {
        jQuery('#ourClubsCreativeRepeater').removeClass('hidden');
        jQuery('#ourClubsCreativeArrows').removeClass('hidden');
        jQuery('#ourClubsCreativeArrows').css('padding-top', '30px');
        jQuery('.our-clubs-sliders .list #creative').addClass('active');
        jQuery('.our-clubs-sliders #creativeGallery').removeClass('hidden');
        jQuery('#creativeGalleryArrows').removeClass('hidden');
    } else if (type == 'fitness') {
        jQuery('#ourClubsDanceFitnessRepeater').removeClass('hidden');
        jQuery('#ourClubsDanceFitnessArrows').removeClass('hidden');
        jQuery('#ourClubsDanceFitnessArrows').css('padding-top', '30px');
        jQuery('.our-clubs-sliders .list #fitness').addClass('active');
        jQuery('.our-clubs-sliders #danceGallery').removeClass('hidden');
        jQuery('#danceGalleryArrows').removeClass('hidden');
    } else if (type == 'arts') {
        jQuery('#ourClubsPerformingArtsRepeater').removeClass('hidden');
        jQuery('#ourClubsPerformingArtsArrows').removeClass('hidden');
        jQuery('#ourClubsPerformingArtsArrows').css('padding-top', '30px');
        jQuery('.our-clubs-sliders .list #arts').addClass('active');
        jQuery('.our-clubs-sliders #artsGallery').removeClass('hidden');
        jQuery('#artsGalleryArrows').removeClass('hidden');
    }
});