jQuery(document).ready(function () {
    jQuery('.leave-us-a-message-form .contact-form-flex .button').on('click', function () {
        jQuery('.leave-us-a-message-form .contact-form-flex .button').removeClass('active');
        jQuery(this).addClass('active');

        var type = jQuery(this).attr('data-text');
        jQuery('input[name="your-type"]').val(type);
    });
});

jQuery(".leave-us-a-message-form .your-contact").on("click touch", function () {
    if (jQuery(".leave-us-a-message-form .your-contact [type=checkbox]:checked").length) {
        jQuery(".leave-us-a-message-form .your-contact [type=checkbox]").attr("checked", false);
    } else {
        jQuery(".leave-us-a-message-form .your-contact [type=checkbox]").attr("checked", true);
    }
});

jQuery(".leave-us-a-message-form .your-share").on("click touch", function () {
    if (jQuery(".leave-us-a-message-form .your-share [type=checkbox]:checked").length) {
        jQuery(".leave-us-a-message-form .your-share [type=checkbox]").attr("checked", false);
    } else {
        jQuery(".leave-us-a-message-form .your-share [type=checkbox]").attr("checked", true);
    }
});

document.addEventListener('wpcf7mailsent', function (event) {
    location = '/contact/thank-you'
}, false);

jQuery('select[name="your-area"]').on('change', function () {
    var type = jQuery(this).val();

    if (type == 'Becoming an Apprentice') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'becomingAnApprenticeContactSubmit');
    } else if (type == 'Hiring an Apprentice') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'hiringAnApprenticeContactSubmit');
    } else if (type == 'Flexi Apprenticeships') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'flexiApprenticeshipsContactSubmit');
    } else if (type == 'Holiday Camps') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'holidayCampsContactSubmit');
    } else if (type == 'Short Courses') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'shortCoursesContactSubmit');
    } else if (type == 'Adult Education') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'adultEducationContactSubmit');
    } else if (type == 'Employability Support') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'employabilitySupportContactSubmit');
    } else if (type == 'Other') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'otherContactSubmit');
    } else if (type == 'Apprenticeship Levy') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'apprenticeshipLevySubmit');
    } else if (type == 'Upskill My Existing Staff') {
        jQuery('.wpcf7-form-control.wpcf7-submit').attr('id', 'upskillMyExistingStaffSubmit');
    }
});