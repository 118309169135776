jQuery('#bigSlider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    // slidesToShow: 1,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '300px',
    rows: 0,
    arrows: true,
    prevArrow: jQuery('.big-slider-prev'),
    nextArrow: jQuery('.big-slider-next'),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                centerMode: 'false',
            }
        }
    ]
});