jQuery('.blog-slider').slick({
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    autoplay: false,
    dots: false,
    rows: 0,
    centerMode: false,
    focusOnSelect: false,
    prevArrow: jQuery(".latest-blogs-slider-prev"),
    nextArrow: jQuery(".latest-blogs-slider-next"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      },
    ]
  }); 

jQuery(document).ready(function() {
  if (jQuery(window).width() < 768) {
    jQuery("#latestBlogPosts").before(jQuery("#latestBlogContent"));
  }
});

jQuery(document).ready(function() {
  if (jQuery(window).width() < 768) {
    jQuery('.latest-blogs .arrows').insertAfter('#latestBlogPosts');
    jQuery('.latest-blogs .arrows').css('margin', 'auto');
    jQuery('.latest-blogs .arrows').css('marginBottom', '50px');
  }
});