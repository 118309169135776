jQuery( '#apprenticeshipsWeOfferSlider' ).slick( {
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  fade: false,
  autoplay: false,
  dots: false,
  rows: 0,
  centerMode: true,
  centerPadding: '100px',
  focusOnSelect: false,
  prevArrow: jQuery( ".apprenticeships-we-offer-prev" ),
  nextArrow: jQuery( ".apprenticeships-we-offer-next" ),
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false
      }
    },
    {
      breakpoint: 639,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 501,
      settings: {
        slidesToShow: 1,
        centerPadding: '10px',
      }
    }
  ]
} );

jQuery( document ).ready( function() {
  jQuery( '.categories li' ).on( 'click', function( e ) {
    console.log( jQuery( this ).attr( 'data-cat' ) );

    var currentlyActive = false;
    if ( jQuery( this ).hasClass( 'active' ) ) {
      currentlyActive = true;
    }

    jQuery( '.categories li' ).removeClass( 'active' );

    if ( !currentlyActive ) {
      jQuery( this ).addClass( 'active' );
      var cat = jQuery( this ).attr( 'data-cat' );
    }

    if ( jQuery( '#apprenticeshipsWeOfferSlider' ).hasClass( 'slick-initialized' ) ) {
      jQuery( '#apprenticeshipsWeOfferSlider' ).slick( 'unslick' );
    }

    // add all children from #apprenticeshipsWeOfferHiddenSlides back to #apprenticeshipsWeOfferSlider
    jQuery( '#apprenticeshipsWeOfferHiddenSlides .card' ).each( function() {
      jQuery( this ).detach().appendTo( '#apprenticeshipsWeOfferSlider' );
    } );

    if ( !currentlyActive ) {
      // move to another hidden element then they can be realoaded on categories li change
      jQuery( '#apprenticeshipsWeOfferSlider .card' ).each( function() {
        if ( jQuery( this ).attr( 'data-cat' ) !== cat ) {
          jQuery( this ).detach().appendTo( '#apprenticeshipsWeOfferHiddenSlides' );
        }
      } );
    }

    jQuery( '#apprenticeshipsWeOfferSlider' ).slick( {
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      autoplay: false,
      dots: false,
      rows: 0,
      centerMode: true,
      centerPadding: '100px',
      focusOnSelect: false,
      prevArrow: jQuery( ".apprenticeships-we-offer-prev" ),
      nextArrow: jQuery( ".apprenticeships-we-offer-next" ),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false
          }
        },
        {
          breakpoint: 639,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 501,
          settings: {
            slidesToShow: 1,
            centerPadding: '10px',
          }
        }
      ]
    } );
  } );
} );

// Add spacing just for the apprenticeships page
if ( window.location.href.indexOf( "apprenticeships" ) > -1 ) {
  jQuery( '.apprenticeships-we-offer' ).css( 'margin-top', '100px' );
}