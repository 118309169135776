jQuery( '.topImage' ).slick( {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  autoplay: false,
  rows: 0,
  asNavFor: '.topImageNav',
  responsive: [
    {
      breakpoint: 510,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '50px',
      }
    },
  ],
} );

jQuery( '.topImageNav' ).slick( {
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.topImage',
  autoplay: false,
  dots: false,
  fade: false,
  arrows: true,
  centerMode: true,
  rows: 0,
  centerPadding: '0',
  prevArrow: '<svg class="slick-arrow slick-prev" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-4" transform="matrix(1,0,0,1,-20,-328)"><path d="M34.742,330.061L24.802,340L34.742,349.939L32.62,352.061L20.56,340L32.62,327.939L34.742,330.061Z"/></g></svg>',
  nextArrow: '<svg class="slick-arrow slick-next" width="15" height="24" viewBox="0 0 15 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Path.-Copy.-3" transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,373.362,352)"><path d="M358.56,340L370.62,327.939L372.742,330.061L362.802,340L372.742,349.939L370.62,352.061L358.56,340Z"/></g></svg>'
} );

jQuery( function( $ ) {

  const newsSliders = document.querySelectorAll( '.more-news-slider' );
  newsSliders.forEach( section => {
    const slider = section.querySelector( '#moreNewsSlider' );
    const $section = $( section );

    jQuery( slider ).slick( {
      slidesToShow: 2.5,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      fade: false,
      arrows: true,
      rows: 0,
      prevArrow: $section.find( ".more-news-prev" ),
      nextArrow: $section.find( ".more-news-next" ),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          }
        },
      ]
    } );
  } );

  const apprentSliders = document.querySelectorAll( '.other-apprenticeships' );
  apprentSliders.forEach( section => {
    const slider = section.querySelector( '#otherApprenticeshipsSlider' );
    const $section = $( section );

    jQuery( slider ).slick( {
      slidesToShow: 2.5,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      fade: false,
      arrows: true,
      rows: 0,
      prevArrow: $section.find( ".other-apprenticeships-prev" ),
      nextArrow: $section.find( ".other-apprenticeships-next" ),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          }
        },
      ]
    } );
  } );

  if ( window.innerWidth < 768 ) {
    jQuery( '.other-apprenticeships .content-panel' ).insertBefore( '#otherApprenticeshipsSlider' );
  }

} );
