const openers = document.querySelectorAll( '[data-modal]' );
const closers = document.querySelectorAll( '.modal-close' );
const modals = document.querySelectorAll( '.modal' );

openers.forEach( opener => {
    opener.addEventListener( 'click', () => {
        const modalId = opener.dataset.modal;
        const modal = document.getElementById( modalId );
        modal.style.display = 'block';
    } );
} );

closers.forEach( closer => {
    closer.addEventListener( 'click', ( e ) => {
        const modal = e.currentTarget.closest( '.modal' );
        closeModal( modal );
    } );
} );

modals.forEach( modal => {
    modal.addEventListener( 'click', ( e ) => {
        if ( e.target === e.currentTarget ) {
            closeModal( modal );
        }
    } );
} );

function closeModal( modal ) {
    if ( document.startViewTransition ) {
        document.startViewTransition( () => modal.style.display = 'none' );
    } else {
        modal.style.display = 'none';
    }
}

// opens the modal when the promo banner is clicked
document.addEventListener( "DOMContentLoaded", function() {
    const promoBanner = document.getElementById( 'promo-banner' );
    if ( promoBanner ) {
        promoBanner.addEventListener( "click", function() {
            const modal = document.getElementById( 'promo-banner-modal' );

            modal.style.display = 'block';
            modal.animate(
                { opacity: [0, 1] },
                {
                    duration: 500,
                    easing: 'ease-in-out'
                } );
        } );
    }
} );


// opens the modal when page loads and sets the session storage based on id with unique hash
document.addEventListener( "DOMContentLoaded", function() {
    const modal = document.querySelector( '.modal-popup-link' )?.closest( '.popup-modal' );
    if ( !modal ) return;

    const modalHash = modal.getAttribute( 'data-popup-modal' );
    console.log( modalHash );
    if ( sessionStorage.getItem( `page-modal-closed-${modalHash}` ) !== 'true' ) {
        setTimeout( function() {
            modal.style.display = 'block';
            modal.style.opacity = 0;

            let opacity = 0;
            const fadeIn = setInterval( function() {
                opacity += 0.1;
                modal.style.opacity = opacity;
                if ( opacity >= 1 ) {
                    clearInterval( fadeIn );
                }
            }, 10 );
        }, 1000 );
    }

} );


//targets closers of the modal that pops up on page load
document.addEventListener( "DOMContentLoaded", function() {
    const popupClosers = document.querySelectorAll( '.popup-modal-close' );

    popupClosers.forEach( popupCloser => {
        popupCloser.addEventListener( 'click', ( e ) => {
            const modal = e.currentTarget.closest( '.modal' );
            const modalHash = modal.getAttribute( 'data-popup-modal' );
            console.log( modalHash );
            sessionStorage.setItem( `page-modal-closed-${modalHash}`, 'true' );
            closeModal( modal );
        } );
    } );
} );
