jQuery(document).ready(function() {
    if (jQuery(window).width() < 768) {
        jQuery('.the-process-steps .steps').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: jQuery(".the-process-steps-prev"),
            nextArrow: jQuery(".the-process-steps-next"),
        });
    }
});