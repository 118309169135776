import AOS from 'aos';
AOS.init( {
    once: true,
    duration: 500,
    anchorPlacement: 'top-bottom',
} );

import 'slick-carousel';
import 'slick-lightbox';

import './template-parts/global.js';
import './template-parts/header.js';
import './template-parts/faqs.js';
import './template-parts/single-post.js';
import './template-parts/homepage-banner.js';
import './template-parts/latest-blogs-slider.js';
import './template-parts/gallery-slider.js';
import './template-parts/gallery-slider-with-nav.js';
import './template-parts/accreditiations-slider.js';
import './template-parts/meet-the-team-slider.js';
import './template-parts/featured-slider-with-background-colour.js';
import './template-parts/ofsted-reports-slider.js';
import './template-parts/our-clubs-sliders.js';
import './template-parts/ajax.js';
import './template-parts/pink-slider-steps.js';
import './template-parts/accordion.js';
import './template-parts/faq-accordions.js';
import './template-parts/apprenticeships-we-offer.js';
import './template-parts/our-awards-slider.js';
import './template-parts/our-courses-gallery';
import './template-parts/leave-us-a-message-form';
import './template-parts/full-width-slider.js';
import './template-parts/static-accreditiation-logos';
import './template-parts/the-process-steps';
import './template-parts/course-faqs';
import './template-parts/big-slider.js';
import './template-parts/modal.js';
import './template-parts/countdown-timer.js';