jQuery('.banner-background').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    rows: 0,
    focusOnSelect: true
  }); 

jQuery('.homepage-banner .arrows-down').on('click', function() {
  jQuery(document).scrollTop(jQuery('.featured-cards').offset().top - 200);
});