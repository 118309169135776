jQuery('#meetTheTeamSlider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '150px',
    prevArrow: jQuery(".meet-the-team-prev"),
    nextArrow: jQuery(".meet-the-team-next"),
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerPadding: '50px',
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '5px',
                initialSlide: -1,
            }
        },
    ]
});

jQuery('#meetTheTeamSlider .slide.clickable').on('click', function(e) {
    if( !e.target.classList.contains('close')) {
        var id = jQuery(this).attr('data-slide-id');
        jQuery('[data-overlay-id="'+id+'"]').fadeIn("slow");

        jQuery(this).find('.name').first().css('opacity', '0');
        jQuery(this).find('.job-title').first().css('opacity', '0');
        jQuery(this).find('.button.bottom').first().css('opacity', '0');
    }
});

jQuery('#meetTheTeamSlider .close').on('click', function() {
    var id = jQuery(this).attr('data-close-id');
    jQuery('[data-overlay-id="'+id+'"]').fadeOut("slow");

    jQuery('#meetTheTeamSlider .slide[data-slide-id="'+id+'"]').find('.name').css('opacity', '1');
    jQuery('#meetTheTeamSlider .slide[data-slide-id="'+id+'"]').find('.job-title').css('opacity', '1');
    jQuery('#meetTheTeamSlider .slide[data-slide-id="'+id+'"]').find('.button.bottom').first().css('opacity', '1');

    return false;
});

jQuery('#meetTheTeamSlider .slide.clickable').on('mouseover', function() {
    jQuery(this).find('.job-title-outer').text('Read Bio');

    var color = jQuery(this).find('.job-title-outer').attr('data-color');
    jQuery(this).find('.job-title-outer').css('color', color);
});

jQuery('#meetTheTeamSlider .slide').on('mouseleave', function() {
    var jobTitle = jQuery(this).attr('data-job-title');

    jQuery(this).find('.job-title-outer').text(jobTitle);
    jQuery(this).find('.job-title-outer').css('color', 'white');
});

jQuery(document).ready(function() {
    jQuery('#meetTheTeamSlider .slide').each(function() {
        var color = jQuery(this).find('.read-bio-mobile').attr('data-color');
        jQuery(this).find('.read-bio-mobile').css('color', color);
    })
});