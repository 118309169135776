// jQuery('#featuredSliderWithBackgroundColour').slick({
//     dots: false,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     arrows: true,
//     centerMode: true,
//     centerPadding: '250px',
//     prevArrow: jQuery(".featured-slider-with-background-colour-prev"),
//     nextArrow: jQuery(".featured-slider-with-background-colour-next"),
//     responsive: [
//         {
//             breakpoint: 1600,
//             settings: {
//                 centerPadding: '100px',
//             }
//         },
//         {
//             breakpoint: 1400,
//             settings: {
//                 centerPadding: '50px',
//             }
//         },
//         {
//             breakpoint: 1200,
//             settings: {
//                 slidesToShow: 3,
//                 centerMode: false,
//             }
//         },
//         {
//             breakpoint: 900,
//             settings: {
//                 slidesToShow: 2,
//                 centerMode: false,
//             }
//         },
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 1,
//                 centerMode: false,
//             }
//         },
//     ]
// });

jQuery('#featuredSliderWithBackgroundColour').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '250px',
    prevArrow: jQuery(".featured-slider-with-background-colour-prev"),
    nextArrow: jQuery(".featured-slider-with-background-colour-next"),
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                centerPadding: '100px',
            }
        },
        {
            breakpoint: 1400,
            settings: {
                centerPadding: '50px',
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                centerMode: false,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                centerMode: false,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                centerMode: false,
            }
        },
    ]
});