jQuery(document).ready(function() {
    if (jQuery(window).width() < 2000) {
        jQuery('.static-accreditation-logos .inner').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000,
            rows: 0,
            focusOnSelect: true,
            prevArrow: jQuery(".static-accreditation-logos-prev"),
            nextArrow: jQuery(".static-accreditation-logos-next"),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        centerMode: true,
                        centerPadding: '100px',
                        rows: 0,
                        focusOnSelect: true,
                    }
                }
            ]
        }); 
    }
});