jQuery("#more_posts").on("click touch", function(e) {
	e.preventDefault();
	let offset = jQuery('#offset').val();
	jQuery('#more_posts p').html('Loading...');
	jQuery('#more_posts').addClass('loading');
	jQuery.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: "more_post_ajax",
			offset: offset,
			// category: jQuery('.cat-selector.posts.active').attr('data-value'),
			category: jQuery('select[name="blog_category"]').find(":selected").val(),
			search: jQuery('input[name="search_blogs"]').val(),
		},
		success: function(res) {
			jQuery('#ajax-container').append(res);
			offset++;
			jQuery('#offset').val(offset);
			jQuery('#more_posts p').html('<a href="#" class="button">Load more</a>');
			jQuery('#more_posts').removeClass('loading');
			if(res == '<div class="small-12 columns" style="text-align:center;"><h4 class="no-posts">No more to display.</h4></div><style>.load-button-container { display:none; }</style>') {
				jQuery('.no-posts').hide();
				jQuery('#more_posts').hide();
				jQuery('.no-more').show();
				setTimeout(function(){
					jQuery('.no-more').fadeOut();
				}, 3500);
			}
		},
		error: function(res) {
			//
		}
	});
});

jQuery("#more_case_studies").on("click touch", function(e) {
	e.preventDefault();
	let offset = jQuery('#offset').val();
	jQuery('#more_posts p').html('Loading...');
	jQuery('#more_posts').addClass('loading');
	jQuery.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: "more_case_studies_ajax",
			offset: offset,
			// category: jQuery('.cat-selector.posts.active').attr('data-value'),
			category: jQuery('select[name="caseStudiesCategory"]').find(":selected").val(),
		},
		success: function(res) {
			jQuery('#ajax-container').append(res);
			offset++;
			jQuery('#offset').val(offset);
			jQuery('#more_posts p').html('<a href="#" class="button">Load more</a>');
			jQuery('#more_posts').removeClass('loading');
			if(res == '<div class="small-12 columns" style="text-align:center;"><h4 class="no-posts">No Case Studies to display.</h4></div><style>.load-button-container { display:none; }</style>') {
				jQuery('.no-posts').hide();
				jQuery('#more_posts').hide();
				jQuery('.no-more').show();
				setTimeout(function(){
					jQuery('.no-more').fadeOut();
				}, 3500);
			}
			if (window.location.href.indexOf('case-studies') > -1) {
				jQuery('.post-img').css('object-fit', 'contain');
			}
		},
		error: function(res) {
			//
		}
	});
});

jQuery("#more_our_courses_gallery").on("click touch", function(e) {
	e.preventDefault();
	let offset = jQuery('#offset').val();
	jQuery('#more_posts p').html('Loading...');
	jQuery('#more_posts').addClass('loading');
	jQuery.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		dataType: 'html',
		data: {
			action: "more_our_courses_gallery_ajax",
			offset: offset,
			// category: jQuery('.cat-selector.posts.active').attr('data-value'),
			category: jQuery('#ourCoursesFilterSelect').find(":selected").val(),
			type: jQuery('#ourCoursesType').text(),
			colour: jQuery('#colour').text(),
		},
		success: function(res) {
			jQuery('#ajax-container').append(res);
			offset++;
			jQuery('#offset').val(offset);
			jQuery('#more_posts p').html('<a href="#" class="button">Load more</a>');
			jQuery('#more_posts').removeClass('loading');
			if(res == '<div class="small-12 columns" style="text-align:center;"><h4 class="no-posts">No Case Studies to display.</h4></div><style>.load-button-container { display:none; }</style>') {
				jQuery('.no-posts').hide();
				jQuery('#more_posts').hide();
				jQuery('.no-more').show();
				setTimeout(function(){
					jQuery('.no-more').fadeOut();
				}, 3500);
			}
		},
		error: function(res) {
			//
		}
	});
});