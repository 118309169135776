jQuery(document).ready(function() {
    if (jQuery(window).width() < 768) {
        jQuery('.course-faqs .inner').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 3000,
            rows: 0,
            focusOnSelect: true,
            prevArrow: jQuery(".course-faqs-prev"),
            nextArrow: jQuery(".course-faqs-next"),
        }); 
    }
});