jQuery('#accreditationsSlider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',
    arrows: true,
    autoplay: true,
    prevArrow: jQuery(".accreditations-slider-prev"),
    nextArrow: jQuery(".accreditations-slider-next"),
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            }
        },
        // {
        //     breakpoint: 550,
        //     settings: {
        //         slidesToShow: 1.6, 
        //         centerMode: true,
        //         centerPadding: '70px',
        //     }
        // }
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1, 
                // centerMode: true,
                // centerPadding: '103px',
            }
        }
    ]
});