
jQuery( 'img.svg' ).each( function() {
  const $img = jQuery( this );
  const imgID = $img.attr( 'id' );
  const imgClass = $img.attr( 'class' );
  const imgURL = $img.attr( 'src' );
  jQuery.get( imgURL, function( data ) {
    var $svg = jQuery( data ).find( 'svg' );
    if ( typeof imgID !== 'undefined' ) {
      $svg = $svg.attr( 'id', imgID );
    }
    if ( typeof imgClass !== 'undefined' ) {
      $svg = $svg.attr( 'class', imgClass + ' replaced-svg' );
    }
    $svg = $svg.removeAttr( 'xmlns:a' );
    if ( !$svg.attr( 'viewBox' ) && $svg.attr( 'height' ) && $svg.attr( 'width' ) ) {
      $svg.attr( 'viewBox', '0 0 ' + $svg.attr( 'height' ) + ' ' + $svg.attr( 'width' ) );
    }
    $img.replaceWith( $svg );
  }, 'xml' );
} );

jQuery( '#toggleFooterForm' ).on( 'click', function() {
  jQuery( '.footer-bottom' ).toggle();
} );

jQuery( document ).ready( function() {
  var el = jQuery( '.first-word-white' ).first().text().split( " " );
  el.unshift( "<span class='white'>" );
  el.splice( 2, 0, '</span>' );
  el = el.join( " " );
  jQuery( '.first-word-white' ).first().html( el );
} );

const videos = document.querySelectorAll( 'iframe[id^="video"]' );
let player;
let players = [];
const playButtons = document.querySelectorAll( '.video__button' );

window.onYouTubePlayerAPIReady = () => {
  for ( let i = 0; i < videos.length; i++ ) {
    player = new YT.Player( videos[i].id, {
      events: {
        'onReady': onPlayerReady
      },
    } );
    players.push( player );
  }
};

function onPlayerReady() {
  for ( let i = 0; i < videos.length; i++ ) {
    // bind events
    playButtons[i].addEventListener( 'click', () => {
      players[i].playVideo();
    } );
  }
}

jQuery( document ).ready( function() {
  jQuery( '.video__button' ).on( 'click', function( e ) {
    var closestVideo = jQuery( this ).closest( '.video-container' ).find( 'iframe' );
    closestVideo.attr( 'src', closestVideo.attr( 'src' ) + "?autoplay=1" );
    e.preventDefault();
    jQuery( this ).hide();
  } );
} );

// jQuery(document).ready(function() {
//   if (jQuery(window).width() < 768) {
//     jQuery('footer .footer-image img').attr('src', '/wp-content/themes/nuimage/assets/images/footer-image-mobile.png');
//   }
// });

jQuery( document ).ready( function() {
  jQuery( '.course-tabs .tablinks' ).on( 'click', function() {
    var name = jQuery( this ).attr( 'data-name' );

    jQuery( '.tablinks svg' ).removeClass( 'active' );

    if ( name == 'role' ) {
      jQuery( '.role-profile-down-arrow' ).addClass( 'active' );
    } else if ( name == 'progression' ) {
      jQuery( '.progression-route-down-arrow' ).addClass( 'active' );
    } else if ( name == 'memberships' ) {
      jQuery( '.memberships-down-arrow' ).addClass( 'active' );
    } else if ( name == 'qualifications' ) {
      jQuery( '.qualifications-down-arrow' ).addClass( 'active' );
    } else if ( name == 'typical' ) {
      jQuery( '.typical-job-roles-down-arrow' ).addClass( 'active' );
    } else if ( name == 'endpoint' ) {
      jQuery( '.end-point-assessments-down-arrow' ).addClass( 'active' );
    }
  } );
} );

function specialistServicesClick() {
  jQuery( '.apprenticeships-we-offer .categories .button[data-cat="Finance apprenticeships"]' ).click();
}
function professionalBusinessClick() {
  jQuery( '.apprenticeships-we-offer .categories .button[data-cat="Professional & business apprenticeships"]' ).click();
}
function teachingEarlyYearsClick() {
  jQuery( '.apprenticeships-we-offer .categories .button[data-cat="Teaching, early years and sports"]' ).click();
}
jQuery( document ).ready( function() {
  if ( window.location.href.indexOf( "flexi-apprenticeships" ) > -1 ) {
    jQuery( '.apprenticeships-we-offer' ).css( 'marginTop', '0' );
  }
  if ( window.location.href.indexOf( "meet-the-team" ) > -1 ) {
    jQuery( '.testimonials' ).css( 'marginTop', '20px' );
    jQuery( '.testimonials' ).css( 'paddingBottom', '0' );
  }
  if ( window.location.href.indexOf( "specialist-services" ) > -1 ) {
    const specialistTimeout = setTimeout( specialistServicesClick, 500 );
  }
  if ( window.location.href.indexOf( "professional-and-business-apprenticeships" ) > -1 ) {
    const professionalBusinessTimeout = setTimeout( professionalBusinessClick, 500 );
  }
  if ( window.location.href.indexOf( "teaching-early-years-and-sports" ) > -1 ) {
    const teachingEarlyYearsTimeout = setTimeout( teachingEarlyYearsClick, 500 );
  }
} );

function visible( partial ) {
  var $t = partial,
    $w = jQuery( window ),
    viewTop = $w.scrollTop(),
    viewBottom = viewTop + $w.height(),
    _top = $t.offset().top,
    _bottom = _top + $t.height(),
    compareTop = partial === true ? _bottom : _top,
    compareBottom = partial === true ? _top : _bottom;

  return ( ( compareBottom <= viewBottom ) && ( compareTop >= viewTop ) && $t.is( ':visible' ) );
}

jQuery( window ).on( 'scroll', function() {
  if ( !jQuery( '.count-digit' ).length ) return;
  if ( !visible( jQuery( '.count-digit' ) ) ) return;
  if ( jQuery( '.count-digit' ).hasClass( 'counter-loaded' ) ) return;

  jQuery( '.count-digit' ).addClass( 'counter-loaded' );
  jQuery( '.count-digit' ).each( function() {
    const $this = jQuery( this );
    jQuery( { Counter: 0 } ).animate( { Counter: $this.text() }, {
      duration: 1500,
      easing: 'swing',
      step: function() {
        $this.text( Math.ceil( this.Counter ) + '%' );
      }
    } );
  } );
} );

jQuery( document ).on( 'ready', function() {
  if ( window.location.href.indexOf( "case-studies" ) > -1 ) {
    jQuery( '.menu-item-1830' ).addClass( 'current-menu-item' );
    jQuery( '.menu-item-1830' ).addClass( 'current_page_item' );
  }

  if ( ( window.location.href.indexOf( "contact" ) > -1 ) || ( window.location.href.indexOf( "thank-you" ) > -1 ) ) {
    jQuery( '#watermark1' ).hide();
  }

  if ( window.location.href.indexOf( "become-an-employer-partner" ) > -1 ) {
    jQuery( '.the-process-steps' ).addClass( 'block-blue' );
  }

  if ( window.location.href.indexOf( "staff-upskill-reskill" ) > -1 ) {
    jQuery( '.static-accreditation-logos' ).addClass( 'block-blue' );
  }

  if ( window.location.href.indexOf( 'case-studies' ) > -1 ) {
    jQuery( '.post-img' ).css( 'object-fit', 'contain' );
  }

  if ( ( window.location.href.indexOf( 'hire-an-apprentice' ) > -1 ) ) {
    jQuery( '.mid-page-pink-banner .inner' ).css( 'background-color', '#1A2645' );
  }

  //!!!! todo - make button the dark blue
  if ( ( window.location.href.indexOf( 'hire-an-apprentice' ) > -1 ) ) {
    jQuery( '.mid-page-pink-banner .inner .button' ).css( 'background-color', '#1A2645' );
  }

  if (
    ( window.location.href.indexOf( 'short-courses' ) > -1 ) ||
    ( window.location.href.indexOf( 'adult-education-courses' ) > -1 ) ||
    ( window.location.href.indexOf( 'employability-support' ) > -1 )
  ) {
    jQuery( '.learner-login-link' ).hide();
    jQuery( '.mobile-learner-login' ).hide();
  }

  if ( window.location.href.indexOf( 'key-policies' ) > -1 ) {
    jQuery( '.contact-details-block .content .info' ).hide();
  }
} );

jQuery( '.wpcf7-checkbox .wpcf7-list-item' ).click( function() {
  jQuery( this ).find( 'input' ).prop( 'checked', function( i, val ) {
    return !val;
  } );
} );