jQuery('.burgerBun').click(function() {
    jQuery('.burgerBun').toggleClass("open");
    jQuery('.menu-mobile-menu-container').toggleClass("open");
  });
  jQuery('.menu-mobile-menu-container a').click(function() {
    jQuery('.burgerBun').removeClass("open");
    jQuery('.menu-mobile-menu-container').removeClass("open");
  });
  
  jQuery(window).on("scroll resize load", function() {
    if (jQuery(document).scrollTop() > 5) {
      jQuery('header').addClass('scroll');
    } 
    else {
      jQuery('header').removeClass('scroll');
    }
  });

jQuery('.nav-hamburger a').click(function() {
    if (jQuery(window).width() > 1200) {
      jQuery('#sideMenu').animate({
        width: '37%',
        padding: '120px 80px',
      }, 300);
    } else if (jQuery(window).width() > 992) {
      jQuery('#sideMenu').animate({
        width: '45%',
        padding: '80px 10%',
      }, 300);
    } else {
      jQuery('#sideMenu').animate({
        width: '100%',
        padding: '50px',
      }, 300);
    }
    jQuery('#closeBanner').show();
});

jQuery('#closeBanner').click(function() {
    jQuery('#sideMenu').animate({
      width: '0%',
      padding: '0',
    }, 300);
  jQuery('#closeBanner').hide();
  // jQuery('#sideMenu').css('padding', '0');
});


// Navigation hover functions
var t;

jQuery('.employers-item').mouseover(function() {
  lastTimeMouseMoved = new Date().getTime();
  clearTimeout(t);
  t = setTimeout(function() {
    var currentTime = new Date().getTime();
    if (currentTime - lastTimeMouseMoved > 190) {
      jQuery('.employers-nav').addClass('open');
      jQuery('.employers-item').addClass('open');
      var xMenu = jQuery('.menu-primary-menu-container').first().position();
      var leftMenu = xMenu.left; 
      var xItem = jQuery('.employers-item').first().position();
      var leftItem = xItem.left;
      var totalLeft = leftMenu + leftItem;
      jQuery('.employers-nav').css('left', totalLeft + 'px');
      jQuery('.apprenticeships-nav').removeClass('open');
      jQuery('.apprenticeships-item').removeClass('open');
      jQuery('.schools-nav').removeClass('open');
      jQuery('.schools-item').removeClass('open');
      jQuery('.shortcourses-nav').removeClass('open');
      jQuery('.shortcourses-item').removeClass('open');
    }
  }, 200)
});

jQuery('.apprenticeships-item').mouseover(function() {
  lastTimeMouseMoved = new Date().getTime();
  clearTimeout(t);
  t = setTimeout(function() {
    var currentTime = new Date().getTime();
    if (currentTime - lastTimeMouseMoved > 190) {
      jQuery('.apprenticeships-nav').addClass('open');
      jQuery('.apprenticeships-item').addClass('open');
      var xMenu = jQuery('.menu-primary-menu-container').first().position();
      var leftMenu = xMenu.left; 
      var xItem = jQuery('.apprenticeships-item').first().position();
      var leftItem = xItem.left;
      var totalLeft = leftMenu + leftItem;
      jQuery('.apprenticeships-nav').css('left', totalLeft + 'px');
      jQuery('.employers-nav').removeClass('open');
      jQuery('.employers-item').removeClass('open');
      jQuery('.schools-nav').removeClass('open');
      jQuery('.schools-item').removeClass('open');
      jQuery('.shortcourses-nav').removeClass('open');
      jQuery('.shortcourses-item').removeClass('open');
    }
  }, 200)
});

jQuery('.schools-item').mouseover(function() {
  lastTimeMouseMoved = new Date().getTime();
  clearTimeout(t);
  t = setTimeout(function() {
    var currentTime = new Date().getTime();
    if (currentTime - lastTimeMouseMoved > 190) {
      jQuery('.schools-nav').addClass('open');
      jQuery('.schools-item').addClass('open');
      var xMenu = jQuery('.menu-primary-menu-container').first().position();
      var leftMenu = xMenu.left; 
      var xItem = jQuery('.schools-item').first().position();
      var leftItem = xItem.left;
      var totalLeft = leftMenu + leftItem;
      jQuery('.schools-nav').css('left', totalLeft + 'px');
      jQuery('.employers-nav').removeClass('open');
      jQuery('.employers-item').removeClass('open');
      jQuery('.apprenticeships-nav').removeClass('open');
      jQuery('.apprenticeships-item').removeClass('open');
      jQuery('.shortcourses-nav').removeClass('open');
      jQuery('.shortcourses-item').removeClass('open');
    }
  }, 200)
});

jQuery('.shortcourses-item').mouseover(function() {
  lastTimeMouseMoved = new Date().getTime();
  clearTimeout(t);
  t = setTimeout(function() {
    var currentTime = new Date().getTime();
    if (currentTime - lastTimeMouseMoved > 190) {
      jQuery('.shortcourses-nav').addClass('open');
      jQuery('.shortcourses-item').addClass('open');
      var xMenu = jQuery('.menu-primary-menu-container').first().position();
      var leftMenu = xMenu.left; 
      var xItem = jQuery('.shortcourses-item').first().position();
      var leftItem = xItem.left;
      var totalLeft = leftMenu + leftItem;
      jQuery('.shortcourses-nav').css('left', totalLeft + 'px');
      jQuery('.employers-nav').removeClass('open');
      jQuery('.employers-item').removeClass('open');
      jQuery('.apprenticeships-nav').removeClass('open');
      jQuery('.apprenticeships-item').removeClass('open');
      jQuery('.schools-nav').removeClass('open');
      jQuery('.schools-item').removeClass('open');
    }
  }, 200)
});

jQuery(document).ready(function() {
  if(jQuery('.menu-holder').is('.current-page-ancestor, .current-menu-ancestor, .current-menu-parent, .current-menu-item')) {

  } else {
    jQuery('.start-canvas').mouseenter(function(){
      setTimeout(function() { jQuery('.sub-nav').removeClass('open'); }, 200);
      setTimeout(function() { jQuery('.employers-item').removeClass('open'); }, 200);
      setTimeout(function() { jQuery('.apprenticeships-item').removeClass('open'); }, 200);
      setTimeout(function() { jQuery('.schools-item').removeClass('open'); }, 200);
      setTimeout(function() { jQuery('.shortcourses-item').removeClass('open'); }, 200);
    });
    jQuery('.contact-item').mouseenter(function(){
      jQuery('.sub-nav').removeClass('open');
    });
    jQuery('.logo').mouseenter(function(){
      jQuery('.sub-nav').removeClass('open');
    });
  }
});

// jQuery(document).ready(function() {
jQuery(window).on("scroll resize load", function() {
  if (jQuery(window).width() < 1400) {
    jQuery('.menu-primary-menu-container .employers-item').hide();
    jQuery('.menu-primary-menu-container .apprenticeships-item').hide();
    jQuery('.menu-primary-menu-container .schools-item').hide();
    jQuery('.menu-primary-menu-container .shortcourses-item').hide();
    jQuery('.menu-primary-menu-container .csr-item').hide();
  } else {
    jQuery('.menu-primary-menu-container .employers-item').show();
    jQuery('.menu-primary-menu-container .apprenticeships-item').show();
    jQuery('.menu-primary-menu-container .schools-item').show();
    jQuery('.menu-primary-menu-container .shortcourses-item').show();
    jQuery('.menu-primary-menu-container .csr-item').show();
  }
});

// employers-main
// apprenticeships-main
// schools-main
// short-courses-main

jQuery(document).ready(function() {
  if (jQuery(window).width() < 1400) {
    jQuery('.employers-main a').on('click', function(e) {
      e.preventDefault();
      jQuery('.mobile-employers-nav').show();
    });
  }
});

jQuery(document).ready(function() {
  if (jQuery(window).width() < 1400) {
    jQuery('.apprenticeships-main a').on('click', function(e) {
      e.preventDefault();
      jQuery('.mobile-apprenticeships-nav').show();
    });
  }
});

jQuery(document).ready(function() {
  if (jQuery(window).width() < 1400) {
    jQuery('.schools-main a').on('click', function(e) {
      e.preventDefault();
      jQuery('.mobile-schools-nav').show();
    });
  }
});

jQuery(document).ready(function() {
  if (jQuery(window).width() < 1400) {
    jQuery('.short-courses-main a').on('click', function(e) {
      e.preventDefault();
      jQuery('.mobile-short-courses-nav').show();
    });
  }
});

jQuery(document).ready(function() {
  jQuery('.mobile-sub-nav-back').on('click', function(e) {
    jQuery('.mobile-employers-nav').hide();
    jQuery('.mobile-apprenticeships-nav').hide();
    jQuery('.mobile-schools-nav').hide();
    jQuery('.mobile-short-courses-nav').hide();
  })
});

jQuery(document).ready(function() {
  jQuery('.employers-hire-an-apprentice-link').on('click', function(e) {
    e.preventDefault();
    
    location.replace('/hire-an-apprentice?colour=block-blue');
  });
});

const toggleSearchMag = document.querySelector('#toggle-search');
const searchBar = document.querySelector('#searchBar');
const toggleSearchX = document.querySelector('#closeSearch')
toggleSearchMag.addEventListener("click", toggleSearchMobile);
toggleSearchX.addEventListener("click", toggleSearchMobile);
toggleSearchMag.addEventListener("click", showX);
toggleSearchX.addEventListener("click", showMag);

header = document.querySelector('header');


function toggleSearchMobile(){
  searchBar.classList.toggle('extend');
  header.classList.toggle('extended');
}

function showX(){
  toggleSearchMag.classList.add("hide");
  toggleSearchX.classList.remove("hide");
}

function showMag(){
  toggleSearchMag.classList.remove("hide");
  toggleSearchX.classList.add("hide");
}

document.addEventListener('DOMContentLoaded', function () {
  var promoBanner = document.querySelector('#promo-banner');
  if (promoBanner) {
    document.querySelector('header').classList.add('has-promo-banner');
  }
});